import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Chart, registerables } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { initialize_title, modify_title } from '../../main/App';
import { AbstractPage } from '../../main/AbstractPage';

import { getTimeStr } from '../../main/Utility';



Chart.register(...registerables);



class Information extends AbstractPage {
	constructor() {
		super('/feature/apollo.apollo');
		this.state = { info: null, online: 0, offline: 0, num: 0 };
	}

	result(result, info) {
		let online = 0;
		let offline = 0;
		let num = 0;
		info.server.forEach((row) => {
			if (row.updateTime < Math.floor(Date.now() / 1000) - 300) offline += 1; else online += 1;
			num += row.siteNum;
		});
		this.setState({ info: info, online: online, offline: offline, num: num });
	}

	color(value) {
		if (value < 0.8) return '#0070E0';
		if (value < 1) return '#C07000';
		return '#C03030';
	}

	chartServer() {
		return <React.Fragment><Typography><b>{window.lan.apollo.state_server}</b><br /><br /></Typography><div style={{ width: 150 }}><Doughnut options={{ plugins: { legend: false } }} data={{
			labels:
				[window.lan.apollo.offline_server, window.lan.apollo.online_server],
			datasets: [{
				backgroundColor: [this.color(1), this.color(0)],
				data: [this.state.offline, this.state.online],
			}]
		}} /></div></React.Fragment>;
	}

	chartSite() {
		return <React.Fragment><Typography><b>{window.lan.apollo.state_site}</b><br /><br /></Typography><div style={{ width: 150 }}><Doughnut options={{ plugins: { legend: false } }} data={{
			labels:
				[window.lan.apollo.error_site, window.lan.apollo.normal_site],
			datasets: [{
				backgroundColor: [this.color(1), this.color(0)],
				data: [this.state.info.expiredSite, this.state.num - this.state.info.expiredSite],
			}]
		}} /></div></React.Fragment>;
	}

	tdraw() {
		return (<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
			<Grid item xs={6}>
				<Typography><b>{window.config.site.name}</b><br /><br /></Typography>
				<TableContainer component={Grid}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>{window.lan.apollo.version}</TableCell>
								<TableCell>{window.lan.apollo.client}</TableCell>
								<TableCell>{window.lan.apollo.server}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell>{this.state.info.version}</TableCell>
								<TableCell>{this.state.info.clientAddr}</TableCell>
								<TableCell>{this.state.info.serverAddr}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={6} style={{ marginTop: "-100px" }}>
				<Grid container direction="row" justifyContent="center" alignItems="center" spacing={3} style={{ height: "350px" }}>
					<Grid item>
						{this.chartServer()}<Typography sx={{ fontSize: "23px", marginTop: "-95px" }} color={this.color(this.state.offline > 0 ? 1 : 0)}>{this.state.online + this.state.offline}</Typography>
					</Grid>
					<Grid item>
						{this.chartSite()}<Typography sx={{ fontSize: "23px", marginTop: "-95px" }} color={this.color(this.state.info.expiredSite > 0 ? 1 : 0)}>{this.state.num}</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TableContainer component={Grid}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell style={{ backgroundColor: "#C0C0C0" }}><b>{window.lan.apollo.server_address}</b></TableCell>
								<TableCell style={{ backgroundColor: "#C0C0C0" }}><b>{window.lan.apollo.server_name}</b></TableCell>
								<TableCell style={{ backgroundColor: "#C0C0C0" }}><b>{window.lan.apollo.server_updateTime}</b></TableCell>
								<TableCell style={{ backgroundColor: "#C0C0C0" }}><b>{window.lan.apollo.server_num}</b></TableCell>
								<TableCell style={{ backgroundColor: "#C0C0C0" }}><b>{window.lan.apollo.server_size}</b></TableCell>
								<TableCell style={{ backgroundColor: "#C0C0C0" }}><b>{window.lan.apollo.server_license}</b></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.info.server.map((item) => {
								const style = (item.validTime < Math.floor(Date.now() / 1000) - 300 || item.siteSize > item.size || item.siteLicense > item.license) ? { backgroundColor: "#FFA0A0" } : {};
								return (<TableRow key={item.id}>
									<TableCell style={style}>{item.address}</TableCell>
									<TableCell style={style}>{item.name}</TableCell>
									<TableCell style={style}>{item.updateTime > 0 ? getTimeStr(item.updateTime) : ''}</TableCell>
									<TableCell style={style}>{item.siteNum}</TableCell>
									<TableCell style={style}>{item.siteSize} / {item.size}</TableCell>
									<TableCell style={style}>{item.siteLicense} / {item.license}</TableCell>
								</TableRow>);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>);
	}

	draw() {
		initialize_title();
		modify_title(2, window.lan.apollo.title);
		return this.pdraw([{ info: window.lan.apollo.title }], null, null, this.tdraw());
	}
}



export default Information;